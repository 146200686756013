<template>
  <div class="flex flex-col h-100">
    <div style="flex: 1" class="bg-white mb-2">
      <div
        class="rounded-sm"
        :class="{
          'pb-2': true,
          'border-bottom': index + 1 !== orderItems.length,
        }"
        v-for="(item, index) in orderItems"
        :set="[(p = getProduct(item)), (resultFile = getResultFile(item))]"
        :key="index"
      >
        <b-overlay
          :show="isLoading"
          :variant="`white`"
          :opacity="0.75"
          :blur="``"
          rounded="sm"
          class="h-100"
        >
          <div class="p-1 h-100">
            <div class="d-flex align-items-center justify-content-between mb-1">
              <span class="font-weight-bold" v-if="item.data.product_name">
                {{
                  currLanguage == "en"
                    ? getProductNameEn(item.data.product_name_multilanguages) ||
                      item.data.product_nameitem.data.product_name
                    : item.data.product_name
                }}
              </span>
              <!-- <span
                v-b-tooltip.top
                :title="
                  $t(
                    isCancelOrder
                      ? 'lbl_the_order_has_been_canceled__you_cannot_create_a_result_paper'
                      : checkDisabled(item)
                      ? 'lbl_the_order_does_not_have_results_from_the_processor__you_cannot_create_a_result_paper'
                      : ''
                  )
                "
                v-if="
                  !checkSendedResult(item) &&
                  resultFile.status !== RESULT_ORDER_ITEM_STATUS.APPROVE
                "
              >
                <b-button
                  :disabled="checkDisabled(item)"
                  v-b-modal.ModalCreateResultForm
                  @click="handleCreateResultForm(item)"
                  variant="outline-primary"
                  size="sm"
                >
                  {{
                  $t(
                  item.formData
                  ? "lbl_edit_the_result_form"
                  : "lbl_create_the_result_form"
                  )
                  }}
                </b-button>
              </span>
              <b-badge v-else variant="success">
                {{
                $t("lbl_results_sent")
                }}
              </b-badge>-->
            </div>

            <b-card
              :set="(processorResults = handleGetProcessorResult(item))"
              no-body
              class="shadow-none p-0 m-0"
              bg-variant="white"
            >
              <div
                v-if="processorResults.length || (resultFile && resultFile.url)"
                class="mb-2 bg-light rounded p-1"
              >
                <div v-if="resultFile && resultFile.url">
                  <div class="mb-50">
                    <div class="font-weight-bold">
                      {{ $t("lbl_test_results") }}
                    </div>
                    <div class="font-italic" style="font-size: 11px">
                      ({{ $t("lbl_submit_results") }}:
                      {{ formatDate(resultFile.updated_at) }})
                    </div>
                  </div>
                  <b-link
                    class="pl-1 flex items-center"
                    :href="resultFile && resultFile.url"
                    target="_blank"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style="margin-right: 4px"
                    >
                      <path
                        d="M9 10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9C11 8.73478 10.8946 8.48043 10.7071 8.29289C10.5196 8.10536 10.2652 8 10 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9ZM20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM15 16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16Z"
                        fill="#20419b"
                      />
                    </svg>
                    <span style="word-break: break-word">
                      {{ getFileName(resultFile) }}
                    </span>
                  </b-link>
                </div>
                <div class="mt-1">
                  <div v-if="processorResults && processorResults.length">
                    <div class="font-weight-bold">
                      {{ $t("lbl_attachments") }}
                    </div>
                    <div
                      class="pl-1 mb-1 border-bottom"
                      v-for="result in processorResults"
                      :key="result.id"
                    >
                      <div class="mb-50">
                        <div class="font-weight-bold italic">
                          {{ result.title }}
                        </div>
                        <div class="font-italic" style="font-size: 11px">
                          ({{ $t("lbl_submit_results") }}:
                          {{ formatDate(result.updated_at) }})
                        </div>
                      </div>
                      <b-link
                        class="flex items-center"
                        :href="result && result.url"
                        target="_blank"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          style="margin-right: 4px"
                        >
                          <path
                            d="M9 10H10C10.2652 10 10.5196 9.89464 10.7071 9.70711C10.8946 9.51957 11 9.26522 11 9C11 8.73478 10.8946 8.48043 10.7071 8.29289C10.5196 8.10536 10.2652 8 10 8H9C8.73478 8 8.48043 8.10536 8.29289 8.29289C8.10536 8.48043 8 8.73478 8 9C8 9.26522 8.10536 9.51957 8.29289 9.70711C8.48043 9.89464 8.73478 10 9 10ZM9 12C8.73478 12 8.48043 12.1054 8.29289 12.2929C8.10536 12.4804 8 12.7348 8 13C8 13.2652 8.10536 13.5196 8.29289 13.7071C8.48043 13.8946 8.73478 14 9 14H15C15.2652 14 15.5196 13.8946 15.7071 13.7071C15.8946 13.5196 16 13.2652 16 13C16 12.7348 15.8946 12.4804 15.7071 12.2929C15.5196 12.1054 15.2652 12 15 12H9ZM20 8.94C19.9896 8.84813 19.9695 8.75763 19.94 8.67V8.58C19.8919 8.47718 19.8278 8.38267 19.75 8.3L13.75 2.3C13.6673 2.22222 13.5728 2.15808 13.47 2.11C13.4402 2.10576 13.4099 2.10576 13.38 2.11C13.2784 2.05174 13.1662 2.01434 13.05 2H7C6.20435 2 5.44129 2.31607 4.87868 2.87868C4.31607 3.44129 4 4.20435 4 5V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V9C20 9 20 9 20 8.94ZM14 5.41L16.59 8H15C14.7348 8 14.4804 7.89464 14.2929 7.70711C14.1054 7.51957 14 7.26522 14 7V5.41ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V5C6 4.73478 6.10536 4.48043 6.29289 4.29289C6.48043 4.10536 6.73478 4 7 4H12V7C12 7.79565 12.3161 8.55871 12.8787 9.12132C13.4413 9.68393 14.2044 10 15 10H18V19ZM15 16H9C8.73478 16 8.48043 16.1054 8.29289 16.2929C8.10536 16.4804 8 16.7348 8 17C8 17.2652 8.10536 17.5196 8.29289 17.7071C8.48043 17.8946 8.73478 18 9 18H15C15.2652 18 15.5196 17.8946 15.7071 17.7071C15.8946 17.5196 16 17.2652 16 17C16 16.7348 15.8946 16.4804 15.7071 16.2929C15.5196 16.1054 15.2652 16 15 16Z"
                            fill="#20419b"
                          />
                        </svg>
                        <span style="word-break: break-word">
                          {{ getFileName(result) }}
                        </span>
                      </b-link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mb-2 bg-light rounded p-1"
                v-if="
                  item.order_item_attachments &&
                  item.order_item_attachments.length &&
                  !checkSendedResult(item)
                "
              >
                <div class="font-weight-bold">
                  {{ $t("lbl_select_the_attached_document") }}
                </div>
                <div class="flex flex-col mt-1" style="gap: 10px">
                  <div>
                    <div
                      class="font-weight-bold italic"
                      v-if="
                        item.order_item_attachments[0] &&
                        item.order_item_attachments[0].url
                      "
                    >
                      {{
                        currLanguage == "en"
                          ? getTitleAttachmentEn(
                              item.order_item_attachments[0]
                                .OrderItemAttachmentsMultilanguage
                            ) ||
                            (item.order_item_attachments[0] &&
                              item.order_item_attachments[0].title) ||
                            ""
                          : (item.order_item_attachments[0] &&
                              item.order_item_attachments[0].title) ||
                            ""
                      }}
                    </div>
                    <b-form-checkbox
                      class="cs-checkbox"
                      v-model="item.order_item_attachments[0].isChecked"
                      v-if="
                        item.order_item_attachments[0] &&
                        item.order_item_attachments[0].url
                      "
                    >
                      <b-link
                        class="flex items-center"
                        :href="
                          item.order_item_attachments[0] &&
                          item.order_item_attachments[0].url
                        "
                        target="_blank"
                      >
                        <span style="word-break: break-word">
                          <u>{{
                            getFileName(item.order_item_attachments[0])
                          }}</u>
                        </span>
                      </b-link>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <!-- <span
                  v-b-tooltip.top
                  :title="
                    $t(
                      isCancelOrder
                        ? 'lbl_order_has_been_cancelled'
                        : checkDisabled(item)
                        ? 'lbl_the_order_has_no_results_from_the_processor'
                        : ''
                    )
                  "
                  v-if="resultFile.status !== RESULT_ORDER_ITEM_STATUS.APPROVE"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    :disabled="isLoading || checkDisabled(item)"
                    @click="handleSubmitResultFile(item, resultFile, index)"
                  >{{ $t("lbl_submit_results") }}</b-button>
                </span>-->

                <span
                  v-b-tooltip.top
                  :title="
                    $t(
                      isCancelOrder
                        ? 'Đơn hàng đã bị huỷ, bạn không thể tạo phiếu kết quả'
                        : ''
                    )
                  "
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    :disabled="
                      checkSendedResult(item) ||
                      (item.order_item_attachments[0] &&
                        !item.order_item_attachments[0].url)
                    "
                    @click="handleSubmitResultFile(item, resultFile, index)"
                    >{{ $t("Trả kết quả") }}</b-button
                  >
                </span>
              </div>
            </b-card>
          </div>
        </b-overlay>
      </div>
    </div>

    <StepNavigator disabledNext @back="$emit('onChangeStep', 1)" />

    <ModalCreateResultForm
      @refresh="$emit('refresh', { id: $route.params.id })"
      @onSuccess="handleCreateResultFormSuccess"
      :orderItem="selectedOrderItem"
      :orderDetail="orderDetail"
    />
  </div>
</template>

<script>
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { TiptapEditor } from "@/components";
import appUtils from "@/utils/appUtils";
import {
  ORDER_FORM_DRAFT,
  ORDER_FORM_PROCESSOR_STATUS,
  PRODUCT_TYPES,
  RESULT_FORM_TYPE,
  RESULT_ORDER_ITEM_STATUS,
  RESULT_TYPE,
  SERVICE_GROUP_TYPE,
} from "@/utils/constant";
import Vue from "vue";
import Ripple from "vue-ripple-directive";
import ModalCreateResultForm from "./ModalCreateResultForm.vue";
import StepNavigator from "./StepNavigator.vue";

export default {
  name: "Result",
  props: {
    orderDetail: {
      type: Object,
      required: true,
    },
    isCancelOrder: Boolean,
  },
  components: {
    ToastificationContent,
    TiptapEditor,
    ModalCreateResultForm,
    StepNavigator,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      orderItems: [],
      appUtils,
      selectedOrderItem: {},
      RESULT_ORDER_ITEM_STATUS,
      currLanguage: this.$store.state.language.currLanguage,
    };
  },
  beforeMount() {
    this.getOrderItems(this.orderDetail);
  },
  watch: {
    orderDetail: {
      handler(data) {
        this.getOrderItems(data);
      },
      deep: true,
    },
    "$store.state.language.currLanguage": function () {
      this.currLanguage = this.$store.state.language.currLanguage;
    },
  },
  methods: {
    formatDate(time) {
      const date = moment.utc(time).format();
      return moment.utc(date).local().format("HH:mm DD/MM/YYYY");
    },
    // makingOrderItems(data) {

    //   return data?.order_items?.filter(
    //     item => item?.data?.type === PRODUCT_TYPES.SERVICE
    //   );
    // },
    async makingOrderItems(data) {
      const newData = data?.order_items?.filter(
        (item) => item?.data?.type === PRODUCT_TYPES.SERVICE
      );

      const dataRaw = await Promise.all(
        newData.map(async (item) => {
          const formDataItem = await this.handleGetFormData(item);
          const newItemAttachments = item?.order_item_attachments?.map(
            (itemAttachment) => ({ ...itemAttachment, isChecked: true })
          );

          return {
            ...item,
            formData: formDataItem,
            order_item_attachments: newItemAttachments,
          };
        })
      );

      return dataRaw;
    },
    async getOrderItems(data) {
      this.orderItems = await this.makingOrderItems(data);
      // this.orderItems?.map((item, index) => {
      //   this.isLoading = false;
      // });
    },
    getProduct(data) {
      return data?.data;
    },
    handleClickFile(selectedFile) {
      const url = selectedFile?.url;
      if (!url) return;
      window.open(url, "_blank");
    },
    async handleSendResultToCustomer(record) {
      try {
        if (!record || !record?.id) return;

        const id = record?.id;
        const res = await this.$rf
          .getRequest("CommonRequest")
          .approveAttachmentResult(id);
        if (res?.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("lbl_results_have_been_sent_to_the_customer"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
          this.$emit("refresh", { id: this.orderDetail?.id });
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleCreateResultForm(data) {
      this.selectedOrderItem = data;

      // this.showModalCreateResultForm = true;
    },
    getResultFile(orderItem) {
      return (
        orderItem?.data?.document_link?.find(
          (elm) => elm?.type !== RESULT_FORM_TYPE.PROCESSOR
        ) || {}
      );
    },
    getFileName(data) {
      if (!data?.url) return "";
      const fileName = data?.url.substring(data?.url.lastIndexOf("/") + 1);
      const formatFileName =
        fileName.length <= 53 ? fileName : appUtils.truncateHash(fileName, 50);
      return formatFileName;
    },
    async handleSubmitResultFile(orderItem, resultData) {
      if (!orderItem?.data?.id) return;
      let result = orderItem?.order_item_attachments[0];

      try {
        this.isLoading = true;
        let params = {};
        let attachment_groups = [];
        let attachment_group = {};
        let document_links = [];
        let document_link = {};

        if (result) {
          document_link = {
            title: result?.title || "",
            url: result?.url || "",
            type: RESULT_TYPE.SUPPLIER,
            status: RESULT_TYPE.SUPPLIER,
            order_item_attachment_id: result?.id || 0,
            order_item_id: result?.order_item_id || 0,
          };
          document_links = document_links.concat(document_link);
          attachment_group = {
            group_id: result?.order_attachment_group_id || 0,
            document_link: document_links,
          };
          attachment_groups.push(attachment_group);
          params.attachment_groups = attachment_groups;
        }

        params.order_id = this.orderDetail?.order_id;
        if (!attachment_group?.group_id || attachment_group?.group_id === 0) {
          params.is_processor_result = true;
        }
        await Vue.prototype.$rf
          .getRequest("CommonRequest")
          .supplierReturnResult(params)
          .then(async () => {
            // this.$emit('refresh', { id: this.orderDetail?.id })
            await this.handleSendResult(orderItem);
            this.isLoading = false;
          });
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("lbl_an_error_occurred_while_returning_results"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 2000);
      }
    },
    async handleSendResult(orderItem) {
      let params = {
        id: orderItem.data.id,
        max_steps: orderItem.deliverable_status?.length,
        order_number:
          orderItem.deliverable_status?.[
            orderItem.deliverable_status.length - 1
          ].order_number,
      };
      await this.$rf.getRequest("CommonRequest").postUpdateStatusOrder(params);
      this.$emit("refresh", { id: this.orderDetail?.id });
    },
    handleCreateResultFormSuccess() {
      this.selectedOrderItem = {};
      this.$emit("refresh", { id: this.orderDetail?.id });
    },
    async handleGetFormData(orderItem) {
      if (!orderItem?.data?.id) return;
      try {
        const params = {
          order_item_id: orderItem.data.id,
          order_form_type: ORDER_FORM_DRAFT.RESULT_FORM,
        };

        const response = await this.$rf
          .getRequest("CommonRequest")
          .getDraftOrderForm(params);

        const responseData = response.data?.data;
        if (!responseData?.content) return;
        return JSON.parse(responseData.content);
      } catch (error) {
        console.log(error);
      }
    },
    checkDisabled(orderItem) {
      const processorInfo = orderItem?.data?.order_processor;
      const isProcessorSuccess =
        processorInfo?.status === ORDER_FORM_PROCESSOR_STATUS.COMPLETE;

      return (
        (this.isCancelOrder || !isProcessorSuccess) &&
        orderItem?.service_catalog?.service_category
          ?.service_group_category_id !== SERVICE_GROUP_TYPE.patientVisit
      );
    },
    checkSendedResult(orderItem) {
      return orderItem?.data?.document_link?.every(
        (item) => item?.url && item?.status === 2
      );
    },
    handleGetProcessorResult(orderItem) {
      console.log(
        orderItem?.data?.document_link?.filter(
          (item) => item?.type === RESULT_FORM_TYPE.PROCESSOR
        ) || []
      );
      return (
        orderItem?.data?.document_link?.filter(
          (item) => item?.type === RESULT_FORM_TYPE.PROCESSOR
        ) || []
      );
    },
    getTitleAttachmentEn(arr) {
      return (
        arr?.find((elm) => elm.language == "en")?.title || "Attachment config"
      );
    },
    getProductNameEn(arr) {
      return arr?.find((elm) => elm.language == "en")?.name || "Product name";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cs-checkbox {
  label {
    display: block !important;
  }
}

$brand-primary: #476dd6;
$white: #fff;
$grey-light: #ededed;
%remain-steps {
  &:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 700;
  }
  &:after {
    background-color: $grey-light;
  }
}
.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 0;

  > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $brand-primary;

    &:before {
      content: "\f00c";
      content: "\2713;";
      content: "\10003";
      content: "\10004";
      content: "\2713";
      display: block;
      margin: 0 auto 4px;
      background-color: $white;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border: {
        width: 2px;
        style: solid;
        color: $brand-primary;
        radius: 50%;
      }
    }
    &:after {
      content: "";
      height: 2px;
      width: 100%;
      background-color: $brand-primary;
      position: absolute;
      top: 16px;
      left: 50%;
      z-index: -1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }

    &.is-active {
      @extend %remain-steps;
      &:before {
        background-color: $white;
        border-color: $brand-primary;
      }

      ~ li {
        color: #808080;
        @extend %remain-steps;
        &:before {
          background-color: $grey-light;
          border-color: $grey-light;
        }
      }
    }
  }

  .ellipsis {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; // default 1 line
    -webkit-box-orient: vertical;
  }
}
</style>
